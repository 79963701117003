.search {
    border: none;
    border-bottom: 1px solid #C1C1C1;
    padding-right: 10px;
    padding-bottom: 4px;
    padding-left: 10px;
    width: 200px;
    outline: none;
}

.search-fill {
    border: none;
    outline: none;
    padding-left: 10px;
    width: 220px;
    background: var(--default-grey-color);
    border-radius: 6px;
    min-height: 40px;
}

.search::placeholder, .search-fill::placeholder {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.04em;

    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.disabled {
    cursor: not-allowed;
}