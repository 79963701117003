.input {
    display: flex;
    /* align-items: flex-start; */
    border: 0.5px solid #7B61FF;
    box-sizing: border-box;
    padding: 6px 15px;

    width: 100%;
    height: 33px;
}

input::placeholder {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #C1C1C1;
}

input[type="text"] {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
}

.input-name {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.04em;

    padding-left: 15px;
    padding-bottom: 5px;
}

.input-valid-text {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;
    color: #FF4040;
}

.valid-error{
    border-color: #FF4040;
}