.doc-detail-modal {
    display: grid;
    grid-template-rows: 25px 1fr;
    gap: 25px;
    overflow-y: hidden;
}

.modal-header-text {
    font-weight: 500;
}

.doc-detail-modal-content {
    display: grid;
    height: 100%;
    grid-template-rows: max-content max-content;
    gap: 15px;
    padding-left: 15px;
    overflow: hidden;
    width: max-content;
}

.doc-detail-modal-content-table{
    /* max-height: 30vh; */
    /* display: grid;
    overflow: hidden; */
    
    height: 100%;
}

.doc-detail-modal-content-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr max-content;
}

.doc-detail-modal-content-details-cell {
    display: flex;
    border: 1px solid #c1c1c173;
    padding: 10px;
    flex-direction: column;
    gap: 5px;
}

.doc-detail-modal-button {
    display: grid;
    justify-content: end;
}