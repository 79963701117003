.divWithSwitch {
  font-family: var(--default-font);
  font-style: normal;
  font-weight: 600;
  border: 13px;
  /* flex-grow: 1; */
  display: inline-flex;
  /* flex-flow: row nowrap; */
  /* gap: 5px; */
  justify-content: center;
  align-items: center;

  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.switch {
  position: relative;
  border: solid 3px #7b61ff;
  border-radius: 13px;
  /* display: inline-block; */
  width: 100px;
  height: 27px;

  cursor: pointer;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  right: 0;
  bottom: 0;
  /* background-color: var(--color-secondary-grey-solid-light); */
  background-color: var(--default-grey-color);
  transition: 0.4s;
  border-radius: 13px;
  left: calc(50% - 100px / 2);
  top: calc(50% - 27px / 2);
}

.sliderDisabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.slider::before {
  position: absolute;
  content: "";
  height: 27px;
  width: 50%;
  left: calc(50% - 99.99px / 2);
  top: calc(50% - 27px / 2);
  background-color: #7b61ff;
  transition: 0.4s;
  border-radius: 5px 0px 0px 5px;
}

input:checked + .slider {
  /* background-color: var(--color-primary-blue-default); */
  background-color: #f4f4f6;
}

input:checked + .slider::before {
  transform: translateX(49.99px);
  border-radius: 0px 7px 7px 0px;
}

.leftArg {
  position: absolute;
  top: 15%;
  color: grey;
}

.leftArg:hover {
  color: black;
  cursor: pointer;
}

.leftArg-active {
  position: absolute;
  top: 15%;
  color: white;
}

.rightArg {
  position: absolute;
  color: grey;
  top: 15%;
  left: calc(47% + 16.41px);
}

.rightArg:hover {
  color: black;
  cursor: pointer;
}


.rightArg-active {
  position: absolute;
  top: 15%;
  color: white;
  left: calc(45% + 16.41px);
}
