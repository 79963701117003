.tab {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    /* padding: 0px 10px 4px 10px; */
    color: #B0B0B0;
    cursor: pointer;

    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.tab-selected {
    color: #000000;
    border-bottom: 2px solid #7B61FF;
    border-left-width: calc(100%-20px);
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 0px;
    padding-right: 0px;
}

.tab-selected-noline {
    color: #000000;
    background-color: var(--default-grey-color);
    border-radius: 4px 4px 0px 0px;
}