.top-menu {
}

.top-menu-info-bar {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, max-content));

    height: 68px;
    backdrop-filter: blur(14px);
    z-index: 1;
    align-items: center;
    justify-content: space-between;

    background-color: #fff;
    position: relative;
}

.top-menu-info-bar-company {
    display: flex;
    gap: 15px;
    align-items: center;
}