.logo {
    display: grid;
    padding-left: 25px;
    grid-template-columns: 61px 1px max-content;
    grid-gap: 5px;

    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.logo-line {
    height: 25px;
    border-left: 0.5px solid rgba(0, 0, 0, 0.41);
}

.logo-text {
    position: relative;
    top: 2px;

    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
}