.uploadStatus {
    display: grid;
    box-sizing: border-box;
    border-radius: 10px;
    height: 33px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: none;
}

.uploadStatus-text {
    display: flex;
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;
    gap: 5px;

    padding-left: 15px;
    padding-right: 15px;
    color: #FFFFFF;
}

.uploadStatus-success{
    background: #66CB9F;
}

.uploadStatus-error{
    background: #FFADBA;
}