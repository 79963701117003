.profile-bar{
    display: grid;
    position: relative;
    gap: 5px;
    /* align-content: center; */
    height: 100%;
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    
    padding-left: 20px;
    margin-right: 26px;
    justify-items: end;

    cursor: pointer;

    
    align-items: end;
    padding-bottom: 20px;
}

.profile-bar-title{
    display: grid;
    grid-template-columns: max-content 1px max-content;
    gap: 19px;
    align-items: center;
}

.profile-bar-title-menu{
    padding: 4px 5px 1px 5px;
}

.profile-bar-title-menu:hover{
    background: linear-gradient(302.3deg, var(--default-grey-color) 30.63%, #F4F7FF 99.53%);
    border-radius: 7px;
}

.menu-line {
    height: 100%;
    border-left: 0.5px solid rgba(0, 0, 0, 0.41);
}

.profile-bar-title-name{
    margin-bottom: 5px;
}