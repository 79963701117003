.react-calendar {
  padding: 7px;
  width: 450px;
  max-width: 100%;
  background: white;
  /* border: 1px solid #a0a096; */
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
}

.react-calendar button {
  margin: 0;
  border-radius: 7px;
  font-family: var(--default-font);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  /* height: 44px; */
  margin-bottom: 1em;

}

.react-calendar__navigation button {
  background: none;
  border: none;
}

.react-calendar__navigation__label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.react-calendar__navigation__arrow {
  font-size: 30px;
  
  padding-bottom: 10px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.85em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__year-view__months__month{
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  /* padding: 10px 6.6667px; */
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover { 
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  /* background: #ffff76; */
  background: #7b61ff50;
}

.react-calendar__tile--now:hover {
  background: red;
}

.react-calendar__tile--now:enabled:hover {
  background: #7b61ff25;
  /* #e6e6e6*/
}

.react-calendar__tile--hasActive {
  /* background: #76baff; */
  border: 3px solid #7B61FF;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  border: 3px solid #7B61FF;
  /* background-color: white; */
}

.react-calendar__tile--active:active {
  background-color: #5533FF;
}

.react-calendar__tile--active:enabled:hover {
  background: #e6e6e6;
  /* color: white; */
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}