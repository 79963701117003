.table {
  position: relative;
  display: grid;
  grid-template-rows: 50px 1fr max-content;
  overflow-x: scroll;
  gap: 3px;

  width: 100%;

  width: calc(100% - 12px);
  padding-left: 12px;
}

.table::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.table__header__cell {
  text-align: left;
  user-select: none;
}

.table-rows {
  overflow-y: scroll;
  overflow-x: hidden;
}

.table-rows::-webkit-scrollbar {
  width: 15px;
  /* ширина нового скроллбара */
}

.table-rows::-webkit-scrollbar-thumb {
  background: var(--default-grey-color);
  background-size: contain;
  border-radius: 8px;
  border: 2px solid #999999;
}

.table-rows::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
}

.table__cell {
  display: grid;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
}

.table__header__checkbox {
  display: grid;
  padding: 12px;
  width: 74px;
  color: #959595;
  background-color: var(--default-grey-color);
  box-sizing: border-box;
  height: 50px;
}

.table__header__function {
  display: grid;
  padding: 12px;
  width: 74px;
  color: #959595;
  background-color: #f7f7f7;
  box-sizing: border-box;
  height: 50px;
  justify-content: center;
  filter: drop-shadow(-2px 0px 5px rgba(0, 0, 0, 0.1));
}

.table__cell__function {
  display: grid;
  padding: 12px;
  width: 74px;
  box-sizing: border-box;
  justify-content: center;
  border-bottom: 1.5px solid #c1c1c173;
  box-sizing: border-box;
  align-items: center;
  background: #FFFFFF;
  filter: drop-shadow(-2px 0px 3px rgba(0, 0, 0, 0.1));
  position: relative;
  cursor: pointer;

  /* Временно */
  justify-items: end;

}

.table__cell_function_icon:hover {
  opacity: 0.5;
}

.table__cell__checkbox {
  display: grid;
  align-items: center;
  padding: 12px;
  width: 74px;
  border-bottom: 1.5px solid #c1c1c173;
  box-sizing: border-box;
  height: 60px;
}

.header__cell {
  display: grid;
  grid-template-columns: 1fr max-content;
  height: 50px;
  padding: 6px 4px 6px 24px;
  background-color: var(--default-grey-color);
  color: #000000;
  font-family: var(--default-font);
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;

  overflow: hidden;
  text-overflow: ellipsis;
}

.cell {
  display: grid;
  /* position: relative; */
  /* background-color: #fff; */
  padding: 6px 4px 6px 24px;
  border-bottom: 1.5px solid #c1c1c173;
  box-sizing: border-box;
  font-family: var(--default-table-font);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  resize: none;
  /* min-width: 5px; */
  height: 60px;
  align-items: center;
}

.table__row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5px, max-content));
  text-align: center;
  height: auto;
}

.table__row__total {
  display: grid;
  background-color: #fff;
  height: 40px;
  grid-template-columns: 74px repeat(auto-fit, minmax(5px, max-content));
  /* margin-top: 10px; */
  /* grid-template-columns: repeat(auto-fit, minmax(5px, max-content)); */
  text-align: center;
  align-items: center;
  /* height: auto; */
  justify-items: center;
}

.table__row__total__length {
  /* padding-left: 20px; */
  font-family: var(--default-font);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #B0B0B0;
  display: flex;
  align-items: center;
  letter-spacing: -0.04em;
}

.table__row__total__cell {
  display: flex;
  font-family: var(--default-font);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: -0.04em;
}

.table__row__total__cell__text{
  padding-left: 24px;
}

.table__row__header {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5px, max-content));
  text-align: center;
  height: auto;
  width: calc(100% - 15px);
}

.table__row:hover {
  background-color: #e6e6e6;
}

.white {
  background-color: #fff;
}

input[type="text"]:focus {
  outline: none;
  /* outline-color: #7B61FF; */
}

.resizer {
  /* Displayed at the right side of column */
  position: absolute;
  top: 0;
  right: -5;
  padding-bottom: 50px;
  width: 4px;
  cursor: col-resize;
}

.resizer:active {
  background-color: #7b61ff91;
}

.table-loader {
  position: relative;
  display: grid;
  height: 100px;
  width: 100px;
}

.player-wrapper {
  display: grid;
  position: absolute;
  backdrop-filter: blur(1.2px);
  justify-content: center;
  align-items: start;
  align-content: center;
  height: 100%;
  width: 100%;
} 