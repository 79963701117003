  /* status colors */

  /* --color-status-yellow: #fc8d26;
  --color-status-orange: #ed5d2d;
  --color-status-purple: #7b61ff;
  --color-status-blue: #c8afff; */

  .status_ok {
      fill: var(--color-status-green)
  }

  .status-warning {
      fill: var(--color-status-yellow)
  }

  .status-download {
      fill: var(--color-status-blue);
      animation: spin 4s linear infinite;
  }

  .status-info {
      fill: var(--color-status-purple)
  }


  @keyframes spin {
      100% {
          transform: rotate(360deg);
      }
  }

  .userGuide {
      fill: var(--color-userGuide)
  }