.modal-wrapper {
    display: flex-start;
    position: fixed;
    background: rgba(0, 0, 0, .2);
    backdrop-filter: blur(2px);
    width: 100%;
    justify-content: center;
    /* align-items: start; */
    align-content: center;
    left: 0;
    top: 0;
    z-index: 10;
}

.modal-wrapper.open {
    height: 100%;
    width: 100%;
    display: grid;
}

.modal-wrapper.open::-webkit-scrollbar {
    display: none;
}

.modal-body {
    display: grid;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 30px 50px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    padding: 25px;
    gap: 30px;
    overflow: hidden;
}

.modal-wrapper.close {
    height: 0;
    display: none;
}

.modal-title {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;    
}

.modal-text-title{
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 2px;
}

.modal-text{
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.04em;
    align-items: center;
}

.modal-text-info{
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #A9A9A9;
}

.modal-content{
    display: grid;
    gap: 15px;
    padding-left: 15px;
}

.modal-content-block{
    display: grid;
    gap: 5px;
    padding-left: 10px;
}