.header{
    display: grid;
    grid-template-columns: max-content max-content;
    height: 100%;
    background-color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    padding-right: 53px;
    padding-left: 26px;
}

.header-input{
    display: flex;
    gap: 40px;
}

.header-buttons{
    display:grid;
    grid-template-columns: max-content max-content;
    gap: 14px;
}