button {
    box-sizing: border-box;
    border: 1px solid #C1C1C1;
    border-radius: 10px;
    height: 33px;
    width: 100%;
    background: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;

    cursor: pointer;
}

.button-text {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.04em;

    padding-left: 15px;
    padding-right: 15px;
}
/* button-grey-fill */
.button-grey-fill {
    background: linear-gradient(302.3deg, #E9E9EA 30.63%, #F4F7FF 99.53%);
    border: none;
    outline: none;
}

.button-grey-fill:focus {
    /* background: #5533FF; */
}

.button-grey-fill:hover {
    /* background: #5533FF; */
}

.button-grey-fill:active {
    /* background: #5533FF; */
    opacity: .6;
}

.button-grey-fill:disabled {
    /* background: #C1C1C1; */
    opacity: 0.7;
    cursor: not-allowed;
}

/* button-grey-outline */
.button-grey-outline {
    border: solid 1px var(--color-userGuide);
}

.button-grey-outline:focus {
    /* background: #5533FF; */
}

.button-grey-outline:hover {
    /* background: #5533FF; */
}

.button-grey-outline:active {
    /* background: #5533FF; */
    opacity: .6;
}

.button-grey-outline:disabled {
    /* background: #C1C1C1; */
    opacity: 0.7;
    cursor: not-allowed;
}

.button-text-grey-outline {
    color: var(--color-userGuide);
}

/* button-purple-outline */
.button-purple-outline {
    border-color: #7B61FF;
}

.button-purple-outline:focus {
    border-color: #5533FF;
}

.button-purple-outline:hover {
    border-color: #5533FF;
}

.button-purple-outline:active {
    background: #5533ff1c;
    border-color: #5533FF;
    opacity: .7;
}

.button-purple-outline:disabled {
    border-color: #C1C1C1;
    /* opacity: 0.7; */
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.07);
}

.button-text-purple-outline {
    color: #7B61FF;
}

/* button-purple-outline */

/* button-purple-fill */
.button-purple-fill {
    background-color: #7B61FF;
    border: none;
    outline: none;
}

.button-purple-fill:focus {
    background: #5533FF;
}

.button-purple-fill:hover {
    background: #5533FF;
}

.button-purple-fill:active {
    background: #5533FF;
    opacity: .9;
}

.button-purple-fill:disabled {
    /* background: #C1C1C1; */
    background: rgba(0, 0, 0, 0.4);
    cursor: not-allowed;
}

.button-text-purple-fill {
    color: #FFFFFF;
}

/* button-purple-fill */