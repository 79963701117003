.light-table {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: 30px max-content 30px;
    overflow: hidden;
    /* margin-left: 15px; */
}

.light-table-rows {
    display: grid;
    overflow-y: auto;
    scrollbar-gutter: stable;
    overflow-x: hidden;
    max-height: 30vh;
}

.light-table-rows::-webkit-scrollbar {
    width: 15px;
    /* ширина нового скроллбара */
}

.light-table-rows::-webkit-scrollbar-thumb {
    background: var(--default-grey-color);
    background-size: contain;
    border-radius: 8px;
    border: 2px solid #999999;
}

.light-table-rows::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
}

.light-table-row {
    display: flex;
    height: 100%;
}

.light-table-row-cell {
    display: grid;
    border: 1.5px solid #c1c1c173;
    align-items: center;
    justify-items: center;
    padding: 5px 15px 5px 15px;

    font-family: var(--default-font);
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.light-table-header {
    display: flex;
}

.light-table-header-cell {
    display: grid;
    border: 1.5px solid #c1c1c173;
    background-color: var(--default-grey-color);
    align-items: center;
    justify-items: center;
    padding: 5px 15px 5px 15px;

    font-family: var(--default-font);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
}