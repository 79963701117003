.modal-co-search {
    width: 175px;
}

.modal-co {
    display: grid;
    height: 90vh;
    grid-template-rows: 25px 35px 1fr 33px;
    gap: 32px;
}

.modal-co-buttons{
    display: grid;
    grid-template-columns: 156px 156px;
    justify-content: end;
    gap: 15px;
}