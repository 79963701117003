html, body {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: linear-gradient(302.3deg, var(--default-grey-color) 30.63%, #f4f7ff 99.53%); */
  background-color: var(--default-grey-color);
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
  height: 100%;

  /* VAR CSS */
  --default-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  --default-table-font: Verdana, sans-serif, Segoe UI, Roboto, Open Sans;
  /* --default-table-font: Segoe UI; */
  /* status colors */
  --color-status-green: #66cb9f;
  --color-status-yellow: #fc8d26;
  --color-status-orange: #ed5d2d;
  --color-status-purple: #7b61ff;
  --color-status-blue: #c8afff;
  
  --default-grey-color: #F4F4F6;
  --color-button-grey: #b3b3b3;
  --color-userGuide: #7171e3;
}

.App {
  height: 100%;
}

.Main {
  display: grid;
  /* grid-template-rows: 97px 1fr 35px; */
  grid-template-rows: max-content 1fr 35px;
  height: 100%;
}

.player {
  display: grid;
  height: 100%;
  justify-content: center;
  align-items: center;
} 