.drop-menu {
    display: grid;
    grid-template-rows: auto;
    position: absolute;
    z-index: 20;
    opacity: 0.6;
    gap: 0px;
    padding: 0px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);

    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.drop-menu.open {
    gap: 5px;
    top: 100%;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 14px 12px;
    min-width: 190px;
    width: max-content;
    opacity: 1;
    transition: gap .2s, padding .2s, opacity .4s;
    /* justify-items: start; */
}

.drop-menu-item {
    display: grid;
    border-radius: 6px;
    height: 36px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: start;
    align-items: center;
    cursor: pointer;
}

.drop-menu-item-text {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;

    height: 19px;
    padding-bottom: 2px;
    width: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
}

.color-red {
    color: #FF4040;
}

.drop-menu-item:hover {
    background: linear-gradient(302.3deg, var(--default-grey-color) 30.63%, #F4F7FF 99.53%);
}

.drop-menu-item:active {
    opacity: 0.7;
}