.no-access {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}

.no-access-text {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
}

.no-access-button{
    width: 211px;
}

.no-access-image{
    padding-right: 90px;
    padding-bottom: 20px;
}