.group-tabs {
    background-color: #fff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
}

.tabs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5px, max-content));
    gap: 6px;
    height: 30px;
    background-color: #fff;
    padding-left: 26px;
}

.subtabs {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(5px, max-content));
    gap: 26px;
    height: 30px;
    background-color: var(--default-grey-color);
    padding-top: 3px;
    padding-left: 56px;
}