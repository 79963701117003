.span-date-title {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.04em;
    color: #A9A9A9;

    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}


.span-date-content {
    display: grid;
    grid-template-columns: min-content min-content max-content;
    min-width: 174px;
    padding-bottom: 2px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.5);
}

.span-date-input {
    display: grid;
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-right: -15px;
    /* letter-spacing: -0.04em; */

    border: none;
    outline: none;
}

.disabled {
    cursor: not-allowed;
}