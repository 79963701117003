.subtab {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;

    padding-bottom: 4px;

    color: #B0B0B0;
    cursor: pointer;

    -moz-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.subtab-selected {
    color: #000000;
    /* z-index: 1000000; */
    /* background-color: rgb(201, 201, 201); */
    /* background: #7b61ff75; */
    /* position: relative; */
    border-bottom: 2px solid #7b61ff;
    /* box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.03); */
}

.subtab-selected-noline {
    color: #000000;
}