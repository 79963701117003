.modal_user_options {
    display: grid;
    height: max-content;
    grid-template-rows: 25px 1fr 33px;
    gap: 25px;
    width: 431px;
}

.modal_user_options_buttons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 15px;
    width: 100%;
}