.dispatch-notification.close {
    display: grid;
    position: fixed;
    bottom: -100%;
    opacity: 0.1;
    right: 0%;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06);
    border-radius: 16px;
    margin: 30px;
    width: 350px;
    height: 160px;
    border: 1px solid #E7E7E7;
    z-index: 100;

    transition: bottom 3.6s, opacity 1.9s;
}


.dispatch-notification.open {
    display: grid;
    position: fixed;
    bottom: 0%;
    right: 0%;
    margin: 30px;
    opacity: 1;

    width: 350px;
    height: 160px;
    border: 1px solid #E7E7E7;
    z-index: 100;

    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1), 0px 10px 16px rgba(20, 37, 63, 0.06);
    border-radius: 16px;

    transition:  bottom .90s, opacity 1.2s;
}

.dispatch-notification-content{
    display: grid;
    grid-template-rows: 24px 1fr 33px;
    gap: 12px;
    margin: 24px;
}

.dispatch-notification-content-title{
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #16192C;
}

.dispatch-notification-content-context{
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.04em;
    color: #425466;
}

.dispatch-notification-content-button{
    width: 175px;
}

.success{
    background: #DFFFE8;
}

.error{
    background: #FFADBA;
}