.agreementDocDetailModal {
    display: grid;
    height: max-content;
    /* grid-template-rows: 25px 1fr 33px; */
    gap: 25px;
    width: max-content;
    max-width: 800px;
}

.agreementDocDetailModal-button{
    display: grid;
    justify-content: end;
}