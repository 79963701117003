.company-list {
    display: grid;
    grid-template-columns: 1px max-content;
    gap: 15px;

    position: relative;
    align-items: center;
    cursor: pointer;
}

.company-list-drop {
    font-family: var(--default-font);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.04em;
    padding: 5px;
}

.company-list-drop:hover {
    background: linear-gradient(302.3deg, var(--default-grey-color) 30.63%, #F4F7FF 99.53%);
    border-radius: 7px;
}

.company-list-drop-name {
    padding-right: 7px;
}